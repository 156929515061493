import { Button, Flex, Modal, Select, Spin } from "antd";
import useProcedures from "../../hooks/useProcedures";
import ProceduresAPI from "../../api/ProceduresAPI";
import useApp from "../../hooks/useApp";
import './ProceduresModal.scss'
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";

const ProceduresModal = (props: any) => {
  const {
    isProceduresModalOpen,
    setIsProceduresModalOpen,
    baseUrl,
    getAuthToken,
  } = props;
  const proceduresApi = ProceduresAPI({ baseUrl, getAuthToken });
  const {
    closeProceduresModal,
    onChangeQuarter,
    startProcess,
    quartersList,
    selectedQuarter,
    loading,
    onChangePeip,
    selectedPeip,
    generateTires,
    status,
  } = useProcedures({
    setIsProceduresModalOpen,
    proceduresApi,
    isProceduresModalOpen,
  });

  let inProcess = status.status === "In Progress";

  const { peipList } = useApp({ baseUrl, getAuthToken });

  const processes = [
    { title: "Cartera Empresas", process: "sp_Trim_CarteraEmpresas" },
    { title: "CF Datos", process: "sp_Trim_CFDatos" },
    {
      title: "Fichas Fondos Coinversión",
      process: "sp_Trim_FichasFondosCoinv",
    },
  ];
  return (
    <Modal
      title="Start Procedures"
      open={isProceduresModalOpen}
      centered
      footer={null}
      width={650}
      onCancel={closeProceduresModal}
    >
      <Spin spinning={loading}>
        <Flex gap={30} justify="center">
          <div>
            <p>Please select a Quarter to start a process:</p>
            <Flex gap="middle" vertical align="center" justify="center">
              <Select
                onChange={(e) => onChangeQuarter(e)}
                options={quartersList}
                value={selectedQuarter}
                placeholder="Select a Quarter"
                style={{ width: "220px", marginTop: "10px" }}
              />
              {processes.map((process: any) => {
                return (
                  <Button
                    className="process-btn"
                    disabled={!selectedQuarter}
                    onClick={() => startProcess(process.process)}
                    key={process.title}
                  >
                    <p>
                      {process.title}
                      <br />
                      <span>{process.process}</span>
                    </p>
                  </Button>
                );
              })}
            </Flex>
          </div>
          <div>
            <p>Please select a Peip to generate Tires:</p>
            <Flex gap="middle" vertical align="center" justify="center">
              <Select
                onChange={(e: any) => onChangePeip(e)}
                placeholder="Select a Peip"
                value={selectedPeip}
                style={{ width: "220px", marginTop: "10px" }}
              >
                {peipList.map((peip: any) => {
                  return (
                    <Select.Option key={peip.idPeip} value={peip.idPeip}>
                      {peip.peipNameLong}
                    </Select.Option>
                  );
                })}
              </Select>
              <Button
                className="process-btn"
                disabled={!selectedQuarter || !selectedPeip || inProcess}
                onClick={generateTires}
              >
                <p>
                  Generate Trim Tires
                  <br />
                  <span>Trim Tires</span>
                </p>
              </Button>
			 {inProcess && (
				<div className="status-tires-data">
					<LoadingOutlined className="loading-icon"/>
					<div>
						<p>Generating <strong> {status.peip}</strong></p>
						<p>Registries: <strong>{status.registries} </strong></p>
					</div>
				</div>
			)}
			{!inProcess && (
			    <div className="status-tires-data">
					<CheckCircleOutlined className="ok-icon"/>
					<div>
						<p>Status Tires: <strong>Finished </strong></p>
					</div>
				</div>			
			)}
            </Flex>
          </div>
        </Flex>
      </Spin>
    </Modal>
  );
};

export default ProceduresModal;
